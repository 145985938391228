<template>
  <div>
    <loading :active.sync="isLoading"
             :can-cancel="false"
             :is-full-page="fullPage"></loading>
    <form data-vv-scope="cupomCampanhaForm" method="post">
      <div class="row justify-content-md-center p-3">

        <!-- Titulo -->
        <div class="col-12 col-md-12">
          <div class="card">
            <div class="card-body">
              <!-- Título -->
              <div class="row">
                <div class="col-md-11 mt-4 mb-3">
                  <label>{{$t('form.tipo-cupom.titulo')}}</label>
                  <custom-input
                    v-model="formData.titulo"
                    name="formData.titulo"
                    type="text"
                    rootClassName="md-form mb-0"
                    inputClassName="md-form-control"
                    v-validate="{ required: true }"
                    :error="errors.first('formData.titulo')">
                  </custom-input>
                </div>
                <!-- Ativo -->
                <div class="col-md-1 mt-4">
                  <label>{{$t('form.tipo-cupom.ativo')}}</label>
                  <custom-switch
                    :listItems="[{text: '', value: 1}]"
                    v-model="formData.ativo"
                    name="formData.ativo"
                    type="checkbox"
                    stateClassName="p-primary"
                    :error="errors.first('formData.ativo')">
                  </custom-switch>
                </div>

                <!-- Descrição -->
                <div class="col-md-12 mt-4">
                  <label>{{$t('form.tipo-cupom.descricao')}}</label>
                  <custom-textarea
                    v-model="formData.descricao"
                    name="formData.descricao"
                    type="text"
                    rootClassName="md-form mb-0"
                    inputClassName="md-form-control"
                    :rows="3"
                    v-validate="{ required: false }"
                    :error="errors.first('formData.descricao')">
                  </custom-textarea>
                </div>

                <!-- Automático-->
                <div class="col-md-1 mt-4">
                  <label>{{$t('form.tipo-cupom.automatico')}}</label>
                  <custom-switch
                    :listItems="[{text: '', value: 1}]"
                    v-model="formData.automatico"
                    name="formData.automatico"
                    type="checkbox"
                    stateClassName="p-primary"
                    :error="errors.first('formData.automatico')">
                  </custom-switch>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-12" v-show="settingsIsActive">
          <!--  Configurações -->
          <div class="card">
            <div class="card-body">
              <h5 class="box-title mb-3">{{ $t('form.tipo-cupom.section.configuracoes') }}</h5>
              <!-- Para-->
              <div class="row">
                <div class="col-12">
                  <h5 class="box-title mb-3">Para</h5>
                </div>

                <!-- Tipo Cliente -->
                <div class="col-12 col-sm-4 form-group mb-4">
                  <label>{{ this.$i18n.t('form.tipo-cupom.tipoCliente') }}</label>
                  <multiselect
                  v-model="formData.tipoCliente"
                  id="formData.tipoCliente"
                  name="formData.tipoCliente"
                  key="id"
                  :multiple="true"
                  :max="30"
                  track-by="nome"
                  label="nome"
                  :allow-empty="true"
                  placeholder="Selecione um ou mais"
                  :close-on-select="false"
                  :clear-on-select="true"
                  :options="tipoClienteList"
                  :show-labels="false"
                  @search-change="asyncFind"
                >
                  <template slot="noOptions">
                    Digite para pesquisar
                  </template>
                  <template slot="noResult">
                    Ops! Nenhum resultado encontrado
                  </template>
                </multiselect>
                  <transition name="slide" mode="">
                  <small v-if="errors.first('formData.tipoCliente')" class="royalc-error-field">{{ errors.first('formData.tipoCliente') }}</small>
                </transition>
                </div>

                <!-- Segmentação -->
                <div class="col-12 col-sm-4 form-group mb-4">
                  <label>{{ this.$i18n.t('form.tipo-cupom.setor') }}</label>
                  <multiselect
                    v-model="formData.setor"
                    id="formData.setor"
                    name="formData.setor"
                    key="id"
                    :multiple="true"
                    :max="30"
                    track-by="nome"
                    label="nome"
                    :allow-empty="true"
                    placeholder="Selecione um ou mais"
                    :close-on-select="false"
                    :clear-on-select="true"
                    :options="setorList"
                    :show-labels="false"
                    @search-change="asyncFind"
                  >
                    <template slot="noOptions">
                      Digite para pesquisar
                    </template>
                    <template slot="noResult">
                      Ops! Nenhum resultado encontrado
                    </template>
                  </multiselect>
                  <transition name="slide" mode="">
                    <small v-if="errors.first('formData.setor')" class="royalc-error-field">{{ errors.first('formData.setor') }}</small>
                  </transition>
                </div>

                <!-- Cliente -->
                <div class="col-12 col-sm-3 form-group mb-4">
                  <label>{{ this.$i18n.t('form.tipo-cupom.cliente') }}</label>
                  <multiselect
                    v-model="formData.cliente"
                    id="formData.cliente"
                    name="formData.cliente"
                    key="id"
                    :multiple="true"
                    :max="30"
                    track-by="nome"
                    label="nome"
                    :allow-empty="true"
                    placeholder="Selecione um ou mais"
                    :close-on-select="false"
                    :clear-on-select="true"
                    :options="simNaoList"
                    :show-labels="false"
                    @search-change="asyncFind"
                  >
                    <template slot="noOptions">
                      Digite para pesquisar
                    </template>
                    <template slot="noResult">
                      Ops! Nenhum resultado encontrado
                    </template>
                  </multiselect>
                  <transition name="slide" mode="">
                    <small v-if="errors.first('formData.cliente')" class="royalc-error-field">{{ errors.first('formData.cliente') }}</small>
                  </transition>
                </div>

                <!-- Importador -->
                <div class="col-12  col-sm-3 form-group mb-4">
                  <label>{{ this.$i18n.t('form.tipo-cupom.importador') }}</label>
                  <multiselect
                    v-model="formData.importador"
                    id="formData.importador"
                    name="formData.importador"
                    key="id"
                    :multiple="true"
                    :max="30"
                    track-by="nome"
                    label="nome"
                    :allow-empty="true"
                    placeholder="Selecione um ou mais"
                    :close-on-select="false"
                    :clear-on-select="true"
                    :options="simNaoList"
                    :show-labels="false"
                    @search-change="asyncFind"
                  >
                    <template slot="noOptions">
                      Digite para pesquisar
                    </template>
                    <template slot="noResult">
                      Ops! Nenhum resultado encontrado
                    </template>
                  </multiselect>
                  <transition name="slide" mode="">
                    <small v-if="errors.first('formData.importador')" class="royalc-error-field">{{ errors.first('formData.importador') }}</small>
                  </transition>
                </div>

                <!-- Exportador -->
                <div class="col-12 col-sm-3 form-group mb-4">
                  <label>{{ this.$i18n.t('form.tipo-cupom.exportador') }}</label>
                  <multiselect
                    v-model="formData.exportador"
                    id="formData.exportador"
                    name="formData.exportador"
                    key="id"
                    :multiple="true"
                    :max="30"
                    track-by="nome"
                    label="nome"
                    :allow-empty="true"
                    placeholder="Selecione um ou mais"
                    :close-on-select="false"
                    :clear-on-select="true"
                    :options="simNaoList"
                    :show-labels="false"
                    @search-change="asyncFind"
                  >
                    <template slot="noOptions">
                      Digite para pesquisar
                    </template>
                    <template slot="noResult">
                      Ops! Nenhum resultado encontrado
                    </template>
                  </multiselect>
                  <transition name="slide" mode="">
                    <small v-if="errors.first('formData.exportador')" class="royalc-error-field">{{ errors.first('formData.exportador') }}</small>
                  </transition>
                </div>
              </div>

              <div class="row">
                <div class="col-12">
                  <h5 class="box-title mb-3">Com</h5>
                </div>
                <!-- Data Início Vigência -->
                <div class="col-12 col-md-6">
                    <label>{{ this.$i18n.t('form.tipo-cupom.dataInicioVigencia') }}</label>
                    <md-datepicker name="formData.dataInicioVigencia" v-model="formData.dataInicioVigencia"
                      md-immediately v-validate="{ required: settingsIsActive }"/>
                    <transition name="slide" mode="">
                      <small v-if="errors.first('formData.dataInicioVigencia')"
                        class="royalc-error-field">{{ errors.first('formData.dataInicioVigencia') }}</small>
                    </transition>
                </div>
                <!-- Data Validade -->
                <div class="col-12 col-md-6">
                  <label>{{ this.$i18n.t('form.tipo-cupom.dataValidade') }}</label>
                  <md-datepicker name="formData.dataValidade" v-model="formData.dataValidade"
                    md-immediately v-validate="{ required: settingsIsActive }"/>
                  <transition name="slide" mode="">
                    <small v-if="errors.first('formData.dataValidade')"
                      class="royalc-error-field">{{ errors.first('formData.dataValidade') }}</small>
                  </transition>
                </div>
              </div>

              <div class="row">
                <div class="col-12">
                  <h5 class="box-title mb-3">Válido por</h5>
                </div>
                <!-- Duração Dias-->
                <div class="col-sm-12 form-group mb-12">
                  <label>
                    {{ this.$i18n.t('form.tipo-cupom.duracaoDias') }}
                    <span><i class="ft-alert-circle"></i><md-tooltip md-direction="right">Assim que criado o cupom o cliente poderá usar o cupom em X dias </md-tooltip></span>
                  </label>
                  <input name="formData.duracaoDias" class="md-form-control" v-model="formData.duracaoDias"  type="text"
                         v-validate="{ required: settingsIsActive, min_value: 1, max_value: 365 }"
                         :error="errors.first('formData.duracaoDias')" >
                  <transition name="slide" mode="">
                    <small v-if="errors.first('formData.duracaoDias')" class="royalc-error-field">{{ errors.first('formData.duracaoDias') }}</small>
                  </transition>
                </div>
              </div>

              <div class="row">
                <div class="col-12">
                  <h5 class="box-title mb-3">Desconto de</h5>
                </div>
                <!-- Moeda -->
                <div class="col-sm-4 form-group mb-4">
                  <label>{{ this.$i18n.t('form.cupom.moeda') }}</label>
                  <v-select  class="md-form-control" name="formData.idmoeda" label="sigla" :clearable="false" :searchable="true"
                             v-model="formData.idmoeda"
                             @search="currencyFetchOptions" :options="currencyList"
                             v-validate="{ required: false, required_object: false }"
                             :filter-by="currencyFilter" >
                    <template slot="option" slot-scope="option">
                      <div class="d-center">
                        {{ option.nome }} {{ option.sigla ? '(' + option.sigla + ')' : '' }}
                      </div>
                    </template>
                    <template slot="selected-option" slot-scope="option">
                      <div class="selected d-center">
                        {{ option.nome }} {{ option.sigla ? '(' + option.sigla + ')' : '' }}
                      </div>
                    </template>
                  </v-select>
                  <transition name="slide" mode="">
                    <small v-if="errors.first('formData.idmoeda')" class="royalc-error-field">{{ errors.first('formData.idmoeda') }}</small>
                  </transition>
                </div>
                <!-- Valor -->
                <custom-input
                  v-model="formData.valor"
                  name="formData.valor"
                  :label="this.$i18n.t('form.cupom.valor')"
                  type="text"
                  rootClassName="col-sm-4 form-group mb-4"
                  inputClassName="md-form-control"
                  v-validate="{ required: settingsIsActive }"
                  :v-mask="{ 'allowPlus':true, 'alias': 'currency', 'prefix': '', 'groupSeparator': '.', 'radixPoint':','}"
                  :error="submitted && errors.first('formData.valor') ? errors.first('formData.valor') : ''">
                </custom-input>
              </div>
            </div>
          </div>
        </div>

        <!-- Submit -->
        <div class="col-12">
          <button type="button" class="btn btn btn-outline-light pull-right" @click="onSave">Salvar</button>
          <button type="button" class="btn btn-link btn-sm mt-1 pull-right" @click="onCancel">Cancelar</button>
        </div>

      </div>
    </form>
  </div>
</template>

<script>
// Components

// eslint-disable-next-line
import { util } from '@/utils/utils.js'

// Form
import CustomInput from '@/components/Forms/CustomInput.vue'

import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
import CustomSwitch from '@/components/Forms/CustomSwitch.vue'
import CustomTextarea from '@/components/Forms/CustomTextarea.vue'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'
import Multiselect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.min.css'
// eslint-disable-next-line
import '@/assets/vendor/reflow-table/js/reflow-table.js'
import '@/assets/vendor/reflow-table/css/reflow-table.css'

// eslint-disable-next-line
import '@/assets/vendor/bootstrap-datepicker/dist/js/bootstrap-datepicker.min.js'
// eslint-disable-next-line
import '@/assets/vendor/bootstrap-datepicker/dist/locales/bootstrap-datepicker.pt-BR.min.js'
import '@/assets/vendor/bootstrap-datepicker/dist/css/bootstrap-datepicker3.min.css'

// Services
import CurrencyService from '@/services/CurrencyService'
import TipoCupomService from '@/services/TipoCupomService'
import CustomerService from '@/services/CustomerService'
import MailService from '@/services/MailService'

export default {
  name: 'ProcessList',
  metaInfo () {
    return {
      titleTemplate: this.$i18n.t('sidebar.cupom') + ' - %s'
    }
  },
  data () {
    return {
      // Configuration to mount table of selected values
      formData: {
        titulo: null,
        descricao: null,
        automatico: false,
        tipoCliente: null,
        setor: null,
        cliente: [],
        exportador: [],
        importador: [],
        dataInicioVigencia: null,
        dataValidade: null,
        duracaoDias: null,
        ativo: true,
        valor: null,
        idmoeda: null
      },
      formErrors: {},
      isLoading: true,
      fullPage: true,
      // List of options to select on select input
      currencyList: [],
      setorList: [],
      tipoClienteList: [],
      simNaoList: [
        {
          id: 1,
          nome: 'Sim'
        },
        {
          id: 2,
          nome: 'Não'
        }
      ],
      submitted: false
    }
  },
  components: {
    Loading,
    CustomInput,
    CustomSwitch,
    CustomTextarea,
    vSelect,
    Multiselect
  },
  // Apply filters to local filter
  beforeMount () {

  },
  mounted () {
    // this.$store.dispatch('SetShowFilter', true)
    // this.$store.dispatch('setShowSearch', false)
  },
  created () {
    let _this = this
    _this.isLoading = true
    // Load basilares data

    let promises = [
      CurrencyService.getCurrencies(),
      CustomerService.getTipoCliente(),
      MailService.getVariableSearch({ field: 'Segmentacao' })
    ]

    // If is edit populate values
    let id = this.$route.params.id

    if (id) {
      promises.push(TipoCupomService.get(id))
    }

    Promise.all(promises).then((values) => {
      // currencyInfo
      let res = values[0]
      _this.hasData = res.data.hasData
      _this.currencyList = res.data.data

      // Add Opção Moeda do Frete
      _this.currencyList.unshift({ id: null, codigo: null, nome: 'Moeda do frete', sigla: null })

      // Tipos de cliente
      _this.tipoClienteList = values[1].data.data

      // Segmentação
      _this.setorList = values[2].data.data

      // Edit Info
      if (id) {
        let campanha = values[3].data.data
        _this.formData = {
          titulo: campanha.titulo,
          descricao: campanha.descricao,
          automatico: campanha.automatico,
          tipoCliente: campanha.tipoCliente,
          setor: campanha.setor,
          cliente: [],
          importador: [],
          exportador: [],
          dataInicioVigencia: null,
          dataValidade: null,
          duracaoDias: campanha.duracaoDias,
          valor: campanha.valor,
          ativo: campanha.ativo,
          idmoeda: campanha.idmoeda
        }

        if (campanha.cliente === true) {
          _this.formData.cliente.push({
            id: 1,
            nome: 'Sim'
          })
        } else if (campanha.cliente === false) {
          _this.formData.cliente.push({
            id: 2,
            nome: 'Não'
          })
        }

        if (campanha.importador === true) {
          _this.formData.importador.push({
            id: 1,
            nome: 'Sim'
          })
        } else if (campanha.importador === false) {
          _this.formData.importador.push({
            id: 2,
            nome: 'Não'
          })
        }

        if (campanha.exportador === true) {
          _this.formData.exportador.push({
            id: 1,
            nome: 'Sim'
          })
        } else if (campanha.exportador === false) {
          _this.formData.exportador.push({
            id: 2,
            nome: 'Não'
          })
        }

        if (campanha.dataInicioVigencia) {
          _this.formData.dataInicioVigencia = new Date(campanha.dataInicioVigencia)
        }

        if (campanha.dataValidade) {
          _this.formData.dataValidade = new Date(campanha.dataValidade)
        }
      }

      _this.isLoading = false
    }).catch(() => {
      _this.isLoading = false
    })
  },
  methods: {
    getSimNaoValue (values) {
      // Todas as opções
      if (values.length <= 0 || values.length > 1) {
        return null
      }

      if (values[0].id === 1) {
        return true
      }

      return false
    },
    asyncFind (query, fieldId) {
      let _this = this

      let filterField = _this.task.filters.find((x) => {
        return x.fieldA.codigo === fieldId
      })

      if (query) {
        _this.isLoadingSearch = true

        let filter = {
          searchTerm: query,
          field: fieldId
        }

        MailService.getVariableSearch(filter).then(res => {
          filterField.options = []
          filterField.options = res.data.data
          _this.isLoadingSearch = false
        })
      } else {
        filterField.options = []
      }
    },
    onCancel (e) {
      var _this = this
      _this.$router.push({ name: 'CupomCampanhaIndex' })
    },
    /**
     * Triggered when the customer search text changes.
     *
     * @param search  {String}   Current search text
     * @param loading {Function} Toggle loading class
     */
    currencyFilter (option, label, search) {
      let temp = search.toLowerCase()

      return (option.nome.toLowerCase().indexOf(temp) > -1 || option.sigla.toLowerCase().indexOf(temp) > -1)
    },
    displayErrors (errors) {
      let formatedErrors = util.getFormErrors(errors)
      this.formErrors = formatedErrors
    },
    /**
     * Triggered when the currency search text changes.
     *
     * @param search  {String}   Current search text
     * @param loading {Function} Toggle loading class
     */
    currencyFetchOptions (search, loading) {
      loading(true)
      let filters = {
        'searchTerm': search
      }

      CurrencyService.getCurrencies(filters).then(res => {
        this.hasData = res.data.hasData
        this.currencyList = res.data.data
        loading(false)
      })
    },
    onSave (e) {
      var _this = this
      this.submitted = true
      _this.$validator.validateAll('cupomCampanhaForm').then(valid => {
        if (valid) {
          _this.isLoading = true

          let data = Object.assign({}, _this.formData)

          if (!this.settingsIsActive) {
            data.dataInicioVigencia = null
            data.dataValidade = null
            data.tipoCliente = null
            data.valor = null
            data.idmoeda = null
            data.duracaoDias = null
          } else {
            data.idmoeda = data.idmoeda.id
            data.cliente = _this.getSimNaoValue(data.cliente)
            data.importador = _this.getSimNaoValue(data.importador)
            data.exportador = _this.getSimNaoValue(data.exportador)
          }

          let id = this.$route.params.id
          if (id) {
            TipoCupomService.put(id, data).then(response => {
              _this.$router.push({ name: 'CupomCampanhaIndex' })
            }).catch(err => {
              if (err.code === 400) {
                _this.displayErrors(err.errors)
              }
              _this.isLoading = false
            })
          } else {
            TipoCupomService.post(data).then(response => {
              _this.$router.push({ name: 'CupomCampanhaIndex' })
            }).finally(() => {
              _this.isLoading = false
            })
          }
        }
      })
    }
  },
  computed: {
    settingsIsActive () {
      if (this.formData.automatico) {
        return true
      }

      return false
    }
  }
}
</script>

<style scoped>
  .swal2-popup {
    width: inherit !important;
  }

  .card-body {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }

  .md-form-control {
    height: 30px;
  }

  .v-select.md-form-control {
    height: 35px;

  }
</style>
